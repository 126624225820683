import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FileParser.css';
import yun_logo from '../images/yun_esg.jpg'
import v_contract from '../images/v_contract.png'
import analytikus from '../images/analytikus.jpg'
import { Card, CardContent, Typography, TextField, Button, IconButton, CircularProgress, InputAdornment } from '@material-ui/core';
import { Email, VpnLock, LockOpen, Person, VpnKey, Visibility, VisibilityOff, PersonAdd } from '@material-ui/icons'
import { isEmpty } from '../custom_libraries/validation';
import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';
import globalVariables from '../custom_libraries/globalVariables';

class SignIn extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        email: '',
        password: '',
        confirm_password: '',
        is_show_password: false,

        spinner_hidden: true,
        button_disabled: false,
    }

    users = [
        {
            'email': 'yun@yun.buzz',
            'password': 'yun'
        },
        {
            'email': 'demo@analytikus.co.za',
            'password': 'test@12345'
        },
        {
            'email': 'abeam@yunesg.com',
            'password': 'Abeam@123'
        },
        {
            'email': 'yundbs@yunesg.com',
            'password': 'thebestbank'
        },
        {
            'email': 'ocbc@yunesg.com',
            'password': 'thebestbank'
        }
    ]

    componentDidMount() {
        // let refresh_token = localStorage.getItem(globalVariables.REFRESH_TOKEN)
        // if(refresh_token !== null){
        //     this.props.history.replace("/");   
        // }
        if (localStorage.getItem('LOGEDIN') === 'true') {
            this.props.history.replace("/");
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleEmail = (evt) => {
        this.setState({ email: evt.target.value });
    }

    handlePassword = (evt) => {
        this.setState({ password: evt.target.value });
    }

    startLoading = () => {
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopLoading = () => {
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    isEmptyFields = () => {

        let data = [this.state.email, this.state.password]

        return isEmpty(data)
    }


    formData = () => {
        let fd = new FormData();

        fd.append('email', this.state.email.trim());
        fd.append('password', this.state.password.trim());

        return fd
    }

    jsonData = () => {
        let data = {
            'email': this.state.email.trim(),
            'password': this.state.password.trim()
        }
        return JSON.stringify(data)
    }



    userLogin = async () => {

        if (this.isEmptyFields()) {
            alert("Fill All Fields !!!")
            return
        }

        this.startLoading()

        // let headers = {}

        // let post_data = this.formData()

        // let data = await post(urls.LOGIN, headers, post_data)

        // if (data !== false) {
        //     // console.log(data)
        //     if(data.hasOwnProperty('success')){
        //         if(data['success'] === true){
        //             localStorage.setItem(globalVariables.AUTH_TOKEN, data['access_token'])
        //             localStorage.setItem(globalVariables.REFRESH_TOKEN, data['refresh_token'])
        //             localStorage.setItem(globalVariables.TOKEN_EXPIRY, this.expiryTime(12))
        //             this.props.history.replace("/");
        //         }
        //         else{
        //             alert(data['message'])
        //         }
        //     }
        // }
        // else{
        //     alert('Something went wrong. Please try again !!!')
        // }

        // Delay of 8 Seconds
        await this.timeout(5000);

        if (this.state.email === this.users[0]['email'] && this.state.password === this.users[0]['password']) {
            localStorage.setItem('LOGEDIN', 'true')
            this.props.history.replace("/");
        }
        else if (this.state.email === this.users[1]['email'] && this.state.password === this.users[1]['password']) {
            localStorage.setItem('LOGEDIN', 'true')
            this.props.history.replace("/");
        }
        else if (this.state.email === this.users[2]['email'] && this.state.password === this.users[2]['password']) {
            localStorage.setItem('LOGEDIN', 'true')
            this.props.history.replace("/");
        }
        else if (this.state.email === this.users[3]['email'] && this.state.password === this.users[3]['password']) {
            localStorage.setItem('LOGEDIN', 'true')
            this.props.history.replace("/");
        }
        else {
            alert('Incorrect email or password !')
        }
        // for(let i=0; i<=this.users.length; i++){
        //     alert(this.state.email+'-------'+this.users[i]['email'])
        //     if(this.state.email === this.users[i]['email'] && this.state.password === this.users[i]['password']){
        //         localStorage.setItem('LOGEDIN', 'true')
        //         this.props.history.replace("/");
        //         return
        //     }
        // }


        this.stopLoading()

    }

    expiryTime = (hour) => {
        let now = new Date();
        now.setHours(now.getHours() + hour);
        return now
    }

    keyPress = (evt) => {
        if (evt.key === "Enter") {
            this.userLogin()
        }
    }


    render() {
        return (
            <div className="d-flex justify-content-center align-items-center h-100" style={{ minHeight: '100vh', backgroundColor: '#F0F4F3' }}>
                <div className="">
                    <Card>
                        <div className='row'>
                            <div className='col-sm-7 pr-0'>
                                <CardContent className='p-0'>
                                    <div className='text-center '>
                                        <div className='pt-3 pb-4'>
                                            <img src={v_contract} style={{ height: '80px' }} className='border-right pr-2' />
                                            <img src={analytikus} style={{ height: '80px' }} className='border-left pl-2' />
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div className="pt-1 text-center">
                                            <TextField
                                                label="Email"
                                                size="small"
                                                variant="outlined"
                                                className="w-75"
                                                value={this.state.email}
                                                onChange={this.handleEmail}
                                                onKeyPress={this.keyPress}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <Email color="action" className="mr-2" />
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </div>

                                        <div className="pt-3 text-center">
                                            <TextField
                                                label="Password"
                                                type={this.state.is_show_password ? 'text' : 'password'}
                                                variant="outlined"
                                                size="small"
                                                className="w-75"
                                                value={this.state.password}
                                                onChange={this.handlePassword}
                                                onKeyPress={this.keyPress}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <VpnKey color="action" className="mr-2" />
                                                        </InputAdornment>,

                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => this.setState({ is_show_password: !this.state.is_show_password })}
                                                            >
                                                                {this.state.is_show_password ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </div>
                                        <div className="pt-3 text-center">
                                            <Button
                                                className='border-bottom'
                                                style={{ textTransform: 'none' }}>
                                                Forgot your password ?
                                            </Button>
                                        </div>

                                        <div className="pt-4 pb-3 text-center">
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className="login_button"
                                                onClick={this.userLogin}
                                                disabled={this.state.button_disabled}>
                                                <CircularProgress className="text-white mr-2" size={24} hidden={this.state.spinner_hidden} />
                                                <LockOpen className="mr-2" />
                                                <b>SIGN IN</b>
                                            </Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </div>
                            <div className='col-sm-5 text-center d-flex justify-content-center align-items-center pl-0' style={{ backgroundColor: '#3AB397' }}>
                                <div className='pt-5 pb-5 pl-4 pr-4'>
                                    <div className='welcome_text'>Hello, Friend!</div>
                                    <div className='text-white'>Enter your personal details and &nbsp;&nbsp;&nbsp;<br />start journey with us</div>
                                    <div className='pt-4'>
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            color='inherit'
                                            className="text-white"
                                            style={{ borderRadius: '25px' }}
                                            onClick={() => this.props.history.replace("/sign_up")}
                                            disabled={this.state.button_disabled}>
                                            <PersonAdd className="mr-2" />
                                            <b>Sign Up</b>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

        );

    }

}

export default SignIn;

