import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, IconButton, Tooltip, Typography, Box, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PowerSettingsNew, } from '@material-ui/icons'
import TabContractReader from './TabContractReader';
import TabNewsReader from './TabNewsReader';
import TabFinancials from './TabFinancials'
import TabFinancialParser from './TabFinancialParser';
import TabFinancialSummary from './TabFinancialSummary';
import yun_logo from '../images/yun_esg.jpg'
import v_contract from '../images/v_contract.png'
import analytikus from '../images/analytikus.jpg'
import './FileParser.css';
import TabLanguageTranslation from './TabLanguageTranslation';
import TabOurProducts from './TabOurProducts';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function Home() {

    let history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('LOGEDIN') !== 'true') {
            history.replace("/sign_in");
        }
    }, []);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const signOut = async () => {
        setTimeout(() => {
            localStorage.clear();
            history.replace("/sign_in");
        }, 500)
    };

    return (
        <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
            <div className="container-fluid border pl-2 pr-2 m-2" style={{ minHeight: '98vh' }}>
                <div className={classes.root}>
                    <div className='row'>
                        <div className='col-md-1 mt-3 pb-2 border-right'>
                            <img src={v_contract} style={{ width: '100%' }} />
                        </div>
                        <div className='col-md-1 mt-3 pb-2 border-left'>
                            <img src={analytikus} style={{ width: '100%' }} />
                        </div>
                        <div className='col-md-10' style={{ display: 'flex' }}>
                            <AppBar position="static" color='default' className='bg-white' style={{ alignSelf: 'flex-end' }}>
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    value={value}
                                    onChange={handleChange} >
                                    <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Contract Reader</span>} />
                                    <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>News Reader</span>} />
                                    <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Financials</span>} />
                                    <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Language Translation</span>} />
                                    <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Our Products</span>} />
                                </Tabs>
                            </AppBar>
                        </div>
                    </div>
                    <div className='border mt-2 w-50'></div>
                    <div className='text-right'>
                        <Tooltip title="SIGN OUT" placement="left-start" arrow>
                            <IconButton className='p-2' onClick={signOut}>
                                <PowerSettingsNew color='primary' />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <TabPanel value={value} index={0}>
                        <TabContractReader />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TabNewsReader />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TabFinancials />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TabLanguageTranslation />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <TabOurProducts />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
}